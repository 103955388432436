<template>
  <div class="d-flex activate-campaign justify-center align-center">
    <v-dialog
      @click:outside="close"
      v-model="dialog"
      overlay-opacity="0.95"
      max-width="485px"
    >
      <template v-slot:activator="{ on }">
        <Images
          v-on="on"
          id="edit-perk-icon-8004bd8a"
          class="pointer edit-perk-icon"
          name="edit_perk.svg"
          v-on:click.native="open"
        />
      </template>
      <v-card class="mycard">
        <v-container style="padding-top: 8px; padding-bottom: 0">
          <v-row
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding-bottom: 8px"
          >
            <v-col cols="1" />
            <v-col cols="8">
              <Texting type="p2b grey2">{{
                form.perkName + " Config"
              }}</Texting>
            </v-col>
            <v-divider style="border: hidden"></v-divider>
            <v-col cols="3" style="padding-right: 20px; height: 57px">
              <Images
                id="activate-campaign-close-icon-b5d4795a"
                name="close.svg"
                style="float: right"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <form ref="form" id="AddForm" @submit.prevent="checkStock">
            <div>
              <v-row style="padding-bottom: 7px; padding-top: 8px">
                <v-col cols="1" />
                <v-col cols="auto">
                  <MySwitch
                    id="activate-perk-switch-173c53f2"
                    :outline="true"
                    @change="onChange"
                    :checked="innerSwitch"
                  />
                </v-col>
                <v-col cols="auto" style="margin-top: 5px; padding-left: 0">
                  <Texting type="p1 grey2">Activate Perkfection</Texting>
                </v-col>
              </v-row>
              <div class="w-100"></div>
              <div
                :class="{ disable: !innerSwitch }"
                style="background-color: #f9f9f9"
              >
                <v-row justify="center">
                  <v-col cols="5" style="padding-top: 35px">
                    <v-row v-if="innerSwitch" style="margin-left: 0">
                      <Texting
                        type="p1 grey1"
                        style="
                          padding-bottom: 7px;
                          padding-left: 4px;
                          padding-right: 7px;
                        "
                        >Min. Stock Desired</Texting
                      >
                      <v-tooltip
                        content-class="my-tooltip"
                        color="rgba(38,39,40,0.9)"
                        max-width="300px"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <span style="height: 16px" v-on="on">
                            <Images
                              style="padding-bottom: 8px"
                              name="interrogative.svg"
                            />
                          </span>
                        </template>
                        <Texting type="p1n mywhite"
                          >The available quantity of your perk will never
                          descend below this limit. Once this limit is reached,
                          your perks will be increased by your top-up
                          quantity.</Texting
                        >
                        <div
                          class="arrow-tooltip"
                          style="margin-left: 4px"
                        ></div>
                      </v-tooltip>
                    </v-row>
                    <v-row v-else style="margin-left: 0">
                      <Texting
                        type="p1 grey2"
                        style="
                          padding-bottom: 7px;
                          padding-left: 4px;
                          padding-right: 7px;
                        "
                        >Min. Stock Desired</Texting
                      >
                      <Images
                        style="padding-bottom: 8px"
                        name="interrogative_disabled.svg"
                      />
                    </v-row>
                    <v-text-field
                      type="number"
                      name="minStockDesired"
                      v-model.number="form.minStockDesired"
                      placeholder="2"
                      :class="{
                        'error--text v-input--has-state':
                          $v.form.minStockDesired.$error,
                      }"
                      :onfocus="(this.placeholder = '')"
                      background-color="#FFFFFF"
                      @keydown="onKeydown"
                      id="minStockDesired-input"
                      outlined
                      dense
                      hide-details
                      :disabled="!innerSwitch"
                      class="number-input"
                      min="0"
                    />
                    <div
                      v-if="
                        $v.form.minStockDesired.$error ||
                        (form.minStockDesired &&
                          form.minStockDesired.toString().length >= 10)
                      "
                      class="redColor"
                      style="padding-top: 5px"
                    >
                      <Texting
                        type="p1"
                        v-if="!$v.form.minStockDesired.required"
                        >"Min. Stock Desired" field is required</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="
                          form.minStockDesired &&
                          form.minStockDesired.toString().length >= 10
                        "
                        >"Min. Stock Desired" field should be less than 10
                        characters</Texting
                      >
                    </div>
                  </v-col>
                  <v-col cols="5" style="padding-top: 35px">
                    <v-row v-if="innerSwitch" style="margin-left: 0">
                      <Texting
                        type="p1 grey1"
                        style="
                          padding-bottom: 7px;
                          padding-left: 4px;
                          padding-right: 7px;
                        "
                        >Top-up increment</Texting
                      >
                      <v-tooltip
                        content-class="my-tooltip"
                        color="rgba(38,39,40,0.9)"
                        max-width="200px"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <span style="height: 16px" v-on="on">
                            <Images
                              style="padding-bottom: 8px"
                              name="interrogative.svg"
                            />
                          </span>
                        </template>
                        <Texting type="p1n mywhite"
                          >The quantity your perk will be increased by each time
                          it reaches the Minimum Perk Quantity.</Texting
                        >
                        <div
                          class="arrow-tooltip"
                          style="margin-left: -47px"
                        ></div>
                      </v-tooltip>
                    </v-row>
                    <v-row v-else style="margin-left: 0">
                      <Texting
                        type="p1 grey2"
                        style="
                          padding-bottom: 7px;
                          padding-left: 4px;
                          padding-right: 7px;
                        "
                        >Top-up increment</Texting
                      >
                      <Images
                        style="padding-bottom: 8px"
                        name="interrogative_disabled.svg"
                      />
                    </v-row>
                    <v-text-field
                      id="topUpIncrement-input"
                      type="number"
                      name="topUpIncrement"
                      v-model.number="form.topUpIncrement"
                      @keydown="onKeydown"
                      placeholder="4"
                      :class="{
                        'error--text v-input--has-state':
                          $v.form.topUpIncrement.$error,
                      }"
                      onfocus="this.placeholder = ''"
                      background-color="#FFFFFF"
                      outlined
                      dense
                      hide-details
                      class="number-input"
                      :disabled="!innerSwitch"
                      min="0"
                    />
                    <div
                      v-if="
                        $v.form.topUpIncrement.$error ||
                        form.topUpIncrement === 0 ||
                        (form.topUpIncrement &&
                          form.topUpIncrement.toString().length >= 10)
                      "
                      class="redColor"
                      style="padding-top: 5px"
                    >
                      <Texting
                        type="p1"
                        v-if="
                          !$v.form.topUpIncrement.required ||
                          form.topUpIncrement === 0
                        "
                        >"Top-up increment" field is required</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="
                          form.topUpIncrement &&
                          form.topUpIncrement.toString().length >= 10
                        "
                        >"Top-up increment" field should be less than 10
                        characters</Texting
                      >
                    </div>
                  </v-col>
                  <v-row
                    justify="start"
                    align="center"
                    style="padding-top: 5px"
                  >
                    <v-col cols="1" style="margin-right: 2%" />
                    <v-col cols="auto">
                      <MySwitch
                        id="limit-maximum sale-switch-66435dec"
                        :disabled="!this.form.isActive"
                        @change="onChange2"
                        :checked="this.form.isLimited"
                        :outline="true"
                      />
                    </v-col>
                    <v-col
                      cols="auto"
                      style="padding-left: 0; padding-right: 0"
                    >
                      <Texting type="p1 grey2">Limit maximum sales</Texting>
                    </v-col>
                    <v-col
                      cols="auto"
                      style="padding-left: 7px; padding-bottom: 4px"
                    >
                      <v-tooltip
                        content-class="my-tooltip"
                        v-if="innerSwitch"
                        color="rgba(38,39,40,0.9)"
                        max-width="300px"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <span style="height: 16px" v-on="on">
                            <Images name="interrogative.svg" />
                          </span>
                        </template>
                        <Texting type="p1n mywhite"
                          >The maximum amount of perks you can sell. Once this
                          many perks have been sold, Perkfection will stop
                          increasing your perk quantity.</Texting
                        >
                        <div
                          class="arrow-tooltip"
                          style="margin-left: 4px"
                        ></div>
                      </v-tooltip>
                      <Images v-else name="interrogative.svg" />
                    </v-col>
                  </v-row>
                  <v-col
                    v-if="this.form.isLimited && this.form.isActive"
                    cols="10"
                    style="padding-bottom: 20px; padding-top: 0"
                  >
                    <v-text-field
                      id="maxSales-input"
                      type="number"
                      name="maxSales"
                      v-model.number="form.maxSales"
                      @keydown="onKeydown"
                      placeholder="500"
                      onfocus="this.placeholder = ''"
                      background-color="#FFFFFF"
                      dense
                      hide-details
                      outlined
                      min="0"
                    />
                    <div
                      v-if="
                        $v.form.maxSales.$error ||
                        (form.maxSales && form.maxSales.toString().length >= 10)
                      "
                      class="redColor"
                      style="padding-top: 5px"
                    >
                      <Texting type="p1" v-if="!$v.form.maxSales.required"
                        >"Max Sales" field is required if you want to limit
                        maximum sales</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="
                          form.maxSales && form.maxSales.toString().length >= 10
                        "
                        >"Max Sales" field should be less than 10
                        characters</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-row
              justify="end"
              align="end"
              style="padding-top: 23px; padding-bottom: 23px"
            >
              <v-col cols="auto" style="padding-right: 4px">
                <Button
                  id="activate-perk-cancel-button-985ae99e"
                  type="cancel"
                  size="normal"
                  texting="p2bold grey2"
                  :handleClick="close"
                  >cancel</Button
                >
              </v-col>
              <v-col cols="auto" class="text-right">
                <Button
                  id="activate-perk-button-a8917bac"
                  b_type="submit"
                  size="normal"
                  :handleClick="clickSave"
                  type="primary"
                  >save</Button
                >
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
    <IncreaseStock
      @increase="onProjectEdit"
      :stock="Number(this.form.minStockDesired)"
      ref="modal"
    />
  </div>
</template>

<script>
import myServices from "../services/resource";
import { required } from "vuelidate/lib/validators";
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import axios from "axios";
import MySwitch from "./MySwitch";
import IncreaseStock from "./IncreaseStock";

export default {
  components: {
    MySwitch,
    Images,
    Texting,
    Button,
    IncreaseStock,
  },
  props: {
    perk: Object,
    opened: Boolean,
    switch_off: Boolean,
  },
  data: () => ({
    dialog: false,
    innerSwitch: "",
    form: {
      minStockDesired: null,
      topUpIncrement: null,
      maxSales: null,
      isActive: null,
      isLimited: null,
    },
  }),
  validations: {
    form: {
      minStockDesired: {
        required,
      },
      topUpIncrement: {
        required,
      },
      maxSales: {
        required,
      },
    },
  },
  watch: {
    opened: function (newval) {
      if (newval) {
        this.dialog = newval;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
      if (val && this.perk.isActive) {
        this.innerSwitch = true;
      }
    },
  },
  mounted() {
    this.form = { ...this.perk };
    this.innerSwitch = this.switch_off;
  },
  methods: {
    onKeydown(event) {
      const char = String.fromCharCode(event.keyCode);
      if (
        char === "E" ||
        char === "m" ||
        char === "k" ||
        char === "»" ||
        char === "n" ||
        char === "¾" ||
        char === "½"
      ) {
        event.preventDefault();
      }
    },
    clickSave() {
      myServices.myAnalyticsEvents(
        this,
        "Perk setup",
        "setup perk, click save button",
      );
      /*this.$ga.event('Perk setup', 'setup perk, click save button');
      this.$mixpanel.track('Perk setup', {
        action: 'setup perk, click save button',
      });*/
    },
    onChange(value) {
      this.innerSwitch = value;
      this.form.isActive = value;
    },
    onChange2(value) {
      this.form.isLimited = value;
    },
    checkStock() {
      if (
        (this.form.minStockDesired !== null &&
          this.form.minStockDesired.toString().length >= 10) ||
        (this.form.topUpIncrement !== null &&
          this.form.topUpIncrement.toString().length >= 10) ||
        (this.form.maxSales !== null &&
          this.form.maxSales.toString().length >= 10 &&
          this.form.isLimited) ||
        this.$v.form.minStockDesired.$invalid ||
        this.$v.form.topUpIncrement.$invalid ||
        this.form.topUpIncrement === 0 ||
        (this.form.isLimited && this.$v.form.maxSales.$invalid)
      ) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      if (
        this.perk.remainingStock >= 5 &&
        this.form.minStockDesired > this.perk.remainingStock &&
        this.form.isActive
      ) {
        this.$refs.modal.show();
      } else if (
        this.perk.remainingStock < 5 &&
        this.form.minStockDesired > 5 &&
        this.form.isActive
      ) {
        this.$refs.modal.show();
      } else {
        this.onProjectEdit();
      }
    },
    onProjectEdit() {
      if (
        this.$v.form.minStockDesired.$invalid ||
        this.$v.form.topUpIncrement.$invalid ||
        this.form.topUpIncrement === 0 ||
        (this.form.isLimited && this.$v.form.maxSales.$invalid)
      ) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }

      const data = {
        minStockDesired: this.form.minStockDesired,
        topUpIncrement: this.form.topUpIncrement,
        maxSales:
          this.form.maxSales >= 0 && this.form.isLimited
            ? this.form.maxSales
            : null,
        isActive: this.form.isActive,
        isLimited: this.form.isLimited,
        projectId: this.form.projectId,
      };

      let notEmpty;
      if (
        this.form.minStockDesired !== this.perk.minStockDesired ||
        this.form.topUpIncrement !== this.perk.topUpIncrement ||
        this.form.maxSales !== this.perk.maxSales ||
        this.form.isActive !== this.perk.isActive ||
        this.form.isLimited !== this.perk.isLimited
      )
        notEmpty = true;

      if (notEmpty) {
        axios
          .put(`/api/perk/editPerk/${this.$props.perk.perkId}`, data)
          .then((res) => {
            const perk = res.data.data;
            this.$emit("child-perk", perk);
            this.$nextTick(() => {
              this.form = { ...perk };
            });
            this.close();
          })
          .catch((error) => {
            this.open();
            myServices.showErrorMessage(this, error);
            /*let message = "Oops, something went wrong";
              if (error.response && error.response.data) {
                message = error.response.data.message;
              }
              this.open();
              this.$toasted.error(message, {
                duration: 5000,
                className: "toast-error",
              });*/
          });
      } else this.dialog = false;
    },
    open: function () {
      return (this.dialog = true);
    },
    close: function () {
      this.dialog = false;
      this.$v.$reset();
      this.innerSwitch = this.switch_off;
      if (!this.form.maxSales) this.form.isLimited = false;
      this.form = { ...this.perk };
    },
  },
};
</script>

<style scoped>
.mycard {
  overflow: hidden;
  box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.disable {
  opacity: 0.3;
}

.edit-perk-icon:hover {
  background-color: #f9f9f9;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
}
.activate-campaign {
  width: 35px;
  height: 35px;
}
.activate-campaign:hover {
  background-color: #f4f4f4;
  border-radius: 50%;
}
</style>
