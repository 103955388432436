<template>
  <div>
    <v-dialog
      overlay-opacity="0.95"
      id="increase-stock-modal-5fb844e2"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="increase-stock-card-756dd5b8"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        "
      >
        <v-container>
          <v-row justify="end">
            <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
              <Images
                name="close.svg"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="11" style="padding-top: 0">
              <v-row justify="center">
                <v-col cols="auto" style="padding-top: 27px">
                  <Images name="attention_red.png" />
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-top: 20px; text-align: center"
                >
                  <Texting type="h3 dark-asphalt"
                    >Increase stock to {{ stock }}?</Texting
                  >
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="
                    padding-top: 2px;
                    padding-bottom: 3px;
                    text-align: center;
                  "
                >
                  <Texting
                    style="margin-bottom: 20px"
                    type="p3 grey2"
                    v-html="texts.one"
                  ></Texting>
                </v-col>
                <v-col
                  cols="auto"
                  style="padding-bottom: 48px; text-align: center"
                >
                  <Texting type="p3 grey2">{{ texts.two }}</Texting>
                </v-col>
              </v-row>
              <v-row justify="center" justify-sm="end">
                <v-col cols="auto">
                  <Button
                    id="increase-stock-button-97c73ff0"
                    type="cancel"
                    size="normal"
                    texting="p3bold grey2"
                    :handleClick="Increase"
                    >yes! increase</Button
                  >
                </v-col>
                <v-col
                  cols="auto"
                  style="padding-right: 12px; padding-left: 3px"
                >
                  <Button
                    id="increase-stock-cancel-button-a64389c6"
                    type="primary"
                    size="normal"
                    texting="p3bold mywhite"
                    :handleClick="close"
                    >cancel</Button
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Button from "./Button";
export default {
  components: { Button, Images, Texting },
  props: {
    stock: {
      type: Number,
    },
  },
  data: () => ({
    dialog: false,
    texts: {
      one: "Тhe stock will be increased to Min. Stock Desired quantity,<br> Аfter that top-up will occur according to the top-up increment.",
      two: "We recommend keeping it as low as possible: from 1 to 5",
    },
  }),
  methods: {
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    Increase() {
      this.$emit("increase");
      this.close();
    },
  },
};
</script>
