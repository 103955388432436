<template>
  <div v-if="isAuthResolved && perks.length > 0" class="wrap">
    <table v-if="perks.length > 0 && oneProject" id="perk-table">
      <thead id="perks-header">
        <tr v-if="sticky" style="height: 70px"></tr>
        <tr
          :class="{ sticky: sticky }"
          id="myHeader"
          style="background-color: #f4f4f4; position: relative"
        >
          <th width="35%" style="padding-left: 27px; text-align: left">
            <Texting type="p1 grey1">Name</Texting>
          </th>
          <th width="10%">
            <v-row align="start" justify="center">
              <v-col cols="auto" style="padding: 0 10px 0 0">
                <Texting type="p1 grey1">Price</Texting>
              </v-col>
              <v-col cols="auto" class="up-arrow">
                <button
                  id="rewards-up-icon-d783d6a6"
                  @click="sortLowest"
                  :class="{ sorted: sorted === 'asc' }"
                  class="up pointer"
                ></button>
              </v-col>
              <v-col cols="auto" class="down-arrow">
                <button
                  id="rewards-down-icon-e719df48"
                  @click="sortHighest"
                  :class="{ sorted: sorted === 'desc' }"
                  class="down pointer"
                ></button>
              </v-col>
            </v-row>
          </th>
          <th width="10%">
            <Texting type="p1 grey1">Total Stock</Texting>
          </th>
          <th width="10%">
            <Texting type="p1 grey1">Remaining Stock</Texting>
          </th>
          <th width="10%">
            <Texting type="p1 grey1">Min. Stock Desired</Texting>
          </th>
          <th width="10%">
            <Texting type="p1 grey1">Top-Up Increment</Texting>
          </th>
          <th width="10%">
            <Texting type="p1 grey1">Max. Sales</Texting>
          </th>
          <th width="5%">
            <Texting type="p1 grey1">Edit</Texting>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="pr"
          v-for="(perk, i) in perks"
          :key="perk.perkId"
          :id="'perk-' + i"
          @click="perkClicked(perk, $event)"
          :style="{
            background: perk.isActive ? '#ffffff' : '#F9F9F9',
            opacity: isArchive ? '.5' : '',
          }"
        >
          <td width="35%" style="padding-left: 11px; text-align: left">
            <div class="d-flex align-center">
              <!--<Images v-if="perk.isActive" name="switch_active.svg" />
							<Images v-else name="switch_disabled.svg" />-->
              <MySwitch
                id="rewards-active-switch-1fc9ecb6"
                :disabled="!perk.isActive"
                @click.native="openModal(perk)"
                @change="onChange($event, perk)"
                :checked="perk.isActive"
              />
              <Texting
                :style="{ opacity: perk.isActive ? '' : '0.35' }"
                class="ml-7"
                type="p2bold grey3"
              >
                {{ perk.perkName }}
                <span
                  v-if="perk.perkType === 'secret'"
                  class="label1 grey5"
                  style="
                    background-color: #333333;
                    border-radius: 3px;
                    padding: 2px 5px;
                    margin-left: 10px;
                  "
                  >Secret</span
                >
                <span
                  v-if="perk.featured === true"
                  class="label1 grey5"
                  style="
                    background-color: #e51075;
                    border-radius: 3px;
                    padding: 2px 5px;
                    margin-left: 10px;
                  "
                  >Featured</span
                >
                <span
                  v-if="perk.perkType === 'add_on' || perk.perkType === 'addon'"
                  class="label1"
                  style="
                    background-color: #eadcec;
                    color: #24124d;
                    border-radius: 3px;
                    padding: 2px 5px;
                    margin-left: 10px;
                  "
                  >Add-on</span
                >
              </Texting>
              <div
                v-if="perk.perkState === 'deliveryDateEnded'"
                class="ml-auto"
              >
                <v-tooltip
                  content-class="my-tooltip"
                  color="rgba(38,39,40,0.9)"
                  max-width="220px"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-flex align-center justify-center">
                      <div class="contain-error">
                        <div class="d-flex error-outline">
                          <Images name="error_outline.svg" />
                        </div>
                      </div>
                      <div class="d-flex pt-1 ml-4">
                        <Texting type="p1bold Fivethly"
                          >Action Required</Texting
                        >
                      </div>
                    </div>
                  </template>
                  <Texting type="p1n mywhite"
                    >Estimated delivery date has expired. You must update the
                    estimated delivery date from Indiegogo.</Texting
                  >
                  <div class="arrow-tooltip" style="left: 82px"></div>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td width="10%">
            <Texting type="p2 grey2">{{
              perk.price || perk.price == 0 ? perk.price : "--"
            }}</Texting>
          </td>
          <td width="10%">
            <Texting type="p2 grey2">{{
              perk.totalStock || perk.totalStock == 0 ? perk.totalStock : "--"
            }}</Texting>
          </td>
          <td width="10%">
            <Texting type="p2 grey2">{{
              perk.remainingStock || perk.remainingStock == 0
                ? perk.remainingStock
                : "--"
            }}</Texting>
          </td>
          <td width="10%">
            <Texting v-if="isArchive || !perk.isActive" type="p2bold grey2">{{
              perk.minStockDesired || perk.minStockDesired == 0
                ? perk.minStockDesired
                : "--"
            }}</Texting>
            <Texting v-else type="p2bold faux_dark">{{
              perk.minStockDesired || perk.minStockDesired == 0
                ? perk.minStockDesired
                : "--"
            }}</Texting>
          </td>
          <td width="10%">
            <Texting v-if="isArchive || !perk.isActive" type="p2bold grey2">{{
              perk.topUpIncrement || perk.topUpIncrement == 0
                ? perk.topUpIncrement
                : "--"
            }}</Texting>
            <Texting v-else type="p2bold faux_dark">{{
              perk.topUpIncrement || perk.topUpIncrement == 0
                ? perk.topUpIncrement
                : "--"
            }}</Texting>
          </td>
          <td width="10%">
            <div style="height: 23px" v-if="isArchive || !perk.isActive">
              <Texting v-if="perk.maxSales" type="p2bold grey2">{{
                perk.maxSales
              }}</Texting>
              <!--<Images
              v-else-if="perk.isActive && (perk.minStockDesired || perk.minStockDesired == 0)  && (perk.topUpIncrement || perk.topUpIncrement == 0)"
              name="infinite_dark.svg"
							/>-->
              <Images
                style="margin-top: 2px"
                width="19px"
                height="19px"
                v-else-if="
                  (perk.minStockDesired || perk.minStockDesired == 0) &&
                  (perk.topUpIncrement || perk.topUpIncrement == 0)
                "
                name="infinity_dark.svg"
              />
              <!--<Texting
              type="h3 grey2"
              v-else-if="perk.isActive && (perk.minStockDesired || perk.minStockDesired == 0)  && (perk.topUpIncrement || perk.topUpIncrement == 0)"
							>∞</Texting>-->
              <Texting v-else type="p2bold grey2">--</Texting>
            </div>
            <div style="height: 23px" v-else>
              <Texting v-if="perk.maxSales" type="p2bold faux_dark">{{
                perk.maxSales
              }}</Texting>
              <!--<Images
              v-else-if="perk.isActive && (perk.minStockDesired || perk.minStockDesired == 0)  && (perk.topUpIncrement || perk.topUpIncrement == 0)"
              name="infinite.svg"
							/>-->
              <!--<Texting
              type="p2bold faux_dark"
              v-else-if="perk.isActive && (perk.minStockDesired || perk.minStockDesired == 0)  && (perk.topUpIncrement || perk.topUpIncrement == 0)"
							>∞</Texting>-->
              <Images
                style="margin-top: 2px"
                width="19px"
                height="19px"
                v-else-if="
                  (perk.minStockDesired || perk.minStockDesired == 0) &&
                  (perk.topUpIncrement || perk.topUpIncrement == 0)
                "
                name="infinity.svg"
              />
              <Texting v-else type="p2bold faux_dark">--</Texting>
            </div>
          </td>
          <td width="5%" style="padding-left: 5px">
            <div class="d-flex justify-center align-center">
              <ActivateCampaign
                :switch_off="switch_standart"
                @closed="activateModal = false"
                :opened="perk.perkId === clicked_perk_id && activateModal"
                v-if="
                  !isArchive &&
                  oneProject.isActiveProject &&
                  perk.perkState !== 'deliveryDateEnded'
                "
                :perk="perk"
                @child-perk="perkValue"
              />
              <div
                v-else-if="isArchive"
                style="height: 35px; width: 35px"
                class="d-flex justify-center align-center"
              >
                <Images class="d-flex" width="14" height="15" name="edit.svg" />
              </div>
              <div
                v-else-if="
                  !oneProject.isActiveProject ||
                  perk.perkState === 'deliveryDateEnded'
                "
                style="height: 35px; width: 35px"
                class="d-flex justify-center align-center"
              >
                <Images class="d-flex" width="14" height="15" name="edit.svg" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import ActivateCampaign from "./ActivateCampaign";
import { mapActions, mapState } from "vuex";
import MySwitch from "./MySwitch";
import axios from "axios";

export default {
  components: {
    MySwitch,
    ActivateCampaign,
    Images,
    Texting,
  },
  props: {
    isArchive: Boolean,
  },
  data: () => ({
    switch_standart: false,
    clicked_perk_id: null,
    activateModal: false,
    windowTop: null,
    sticky: false,
    sorted: null,
    header: "",
    form: {
      isActive: "",
    },
  }),
  computed: {
    ...mapState({
      perks: "perks",
      oneProject: "oneProject",
      isAuthResolved: "isAuthResolved",
      isPerksResolved: "isPerksResolved",
    }),
  },
  async mounted() {
    this.getAllPerks(this.$route.params.projectTitle);
    this.getOneProject(this.$route.params.projectTitle);
    //console.log('project in rewards',this.oneProject);
    //this.interval();
    window.addEventListener("scroll", this.onScroll, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    perkValue: function (param) {
      this.perks.map((perk) => {
        if (perk.perkId == param.perkId) {
          this.$nextTick(() => {
            perk.perkName = param.perkName;
            perk.price = param.price;
            perk.totalStock = param.totalStock;
            perk.remainingStock = param.remainingStock;
            perk.minStockDesired = param.minStockDesired;
            perk.topUpIncrement = param.topUpIncrement;
            perk.maxSales = param.maxSales;
            perk.isActive = param.isActive;
            perk.isLimited = param.isLimited;
          });
        }
      });
    },
    perkClicked(perk, event) {
      if (
        event.target.tagName !== "SPAN" &&
        event.target.tagName !== "INPUT" &&
        this.activateModal === false
      ) {
        this.clicked_perk_id = perk.perkId;
        this.activateModal = true;
      }
    },
    openModal: function (perk) {
      if (
        !this.switch_standart &&
        !perk.isActive &&
        this.oneProject.isActiveProject &&
        perk.perkState !== "deliveryDateEnded"
      ) {
        this.clicked_perk_id = perk.perkId;
        this.activateModal = true;
      } else {
        this.activateModal = false;
      }
    },
    onChange: function (event, perk) {
      this.clicked_perk_id = perk.perkId;
      this.switch_standart = event;
      if (this.switch_standart === false) {
        this.form.isActive = this.switch_standart;
        this.form.projectId = perk.projectId;
        axios
          .put(`/api/perk/endPerkActivation/${perk.perkId}`, this.form)
          .then(() => {
            this.$store.dispatch(
              "getAllPerks",
              this.$route.params.projectTitle,
            );
          })
          .catch((error) => {
            myServices.showErrorMessage(this, error);
            /*let message = "Oops, something went wrong";
						if (error.response && error.response.data) {
							message = error.response.data.message;
						}
						this.$toasted.error(message, {
							duration: 5000,
							className: "toast-error",
						});*/
          });
      }
    },

    sortLowest() {
      this.perks.sort((a, b) =>
        parseInt(a.price) > parseInt(b.price) ? 1 : -1,
      );
      this.sorted = "asc";
    },
    sortHighest() {
      this.perks.sort((a, b) =>
        parseInt(a.price) < parseInt(b.price) ? 1 : -1,
      );
      this.sorted = "desc";
    },
    onScroll() {
      this.windowTop = window.top.scrollY;
      let element = document.getElementById("perk-table");
      let sticky = element.offsetTop;
      this.sticky = window.pageYOffset >= sticky - 70;
    },
    ...mapActions(["getAllPerks"]),
    ...mapActions(["getOneProject"]),
  },
};
</script>

<style scoped>
.pr:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
}
.pr:hover {
  /*box-shadow: 0 1px 2px rgba(8, 35, 48, 0.24), 0 2px 6px rgba(8, 35, 48, 0.16);*/
  box-shadow: 0 1px 2px rgba(8, 35, 48, 0.24), 0 2px 6px rgba(8, 35, 48, 0.16);
  opacity: 0.95;
}
tr {
  text-align: center;
}
td {
  padding-top: 13px;
  padding-bottom: 13px;
}
table {
  width: 100%;
  border-spacing: 0;
  min-width: 600px;
  border-collapse: collapse;
  box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
}
th {
  padding-top: 19px;
  padding-bottom: 19px;
}

.sticky {
  position: fixed !important;
  z-index: 1;
  top: 70px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  min-height: 57px;
  text-align: center;
}
</style>
