<template>
  <v-app style="background-color: #f9f9f9">
    <AppHeaderUser v-if="user" />
    <v-container fluid>
      <div
        v-if="
          (isAuthResolved && perks.length > 0 && oneProject) || emptyProject
        "
        class="d-flex align-center pt-7 pl-3"
      >
        <div
          id="myrewards-back-icon-70d4cffe"
          :class="{ point: hover }"
          @click="$router.back()"
          style="height: 35px"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <Images name="arrow.svg" />
        </div>
        <div class="d-flex ml-3">
          <Texting
            id="myrewards-header-text-8b19aac4"
            @click.native="$router.back()"
            type="p1bold grey2"
            class="pointer"
            >Perks Setup</Texting
          >
        </div>
      </div>

      <v-row justify="space-between" v-if="perks.length > 0">
        <v-col
          cols="auto"
          style="padding-left: 25px; padding-bottom: 30px; padding-top: 36px"
        >
          <Texting type="p3bold grey1">
            Rewards:
            <span style="color: #858585">{{
              this.$route.params.projectTitle
            }}</span>
          </Texting>
        </v-col>
        <v-col v-if="oneProject.isActiveProject === false" cols="auto">
          <Texting
            style="
              padding: 13px 22px;
              background-color: #eadcec;
              border-radius: 10px;
            "
            type="foralert dark-magenta"
          >
            <b>Warning:</b> Campaign is currently inactive. Stock levels reflect
            the data gathered when it was last active.
          </Texting>
        </v-col>
      </v-row>
      <v-row v-if="perks.length > 0">
        <Rewards :is-archive="this.is_archive" />
      </v-row>
    </v-container>
    <v-row
      v-if="emptyProject"
      style="padding-left: 15px; padding-right: 15px; padding-bottom: 20%"
    >
      <EmptyState text="You have not rewards to show yet. Please wait." />
    </v-row>
    <AppFooter />
  </v-app>
</template>

<script>
import Texting from "../components/Texting";
import Rewards from "../components/Rewards";
import Images from "../components/MyImages";
import EmptyState from "../components/EmptyState";
import { mapState, mapActions } from "vuex";
import AppHeaderUser from "../components/AppHeaderUser";
import AppFooter from "../components/AppFooter";

export default {
  components: {
    Images,
    Rewards,
    Texting,
    EmptyState,
    AppHeaderUser,
    AppFooter,
  },
  data: () => ({
    hover: false,
    perks: [],
    oneProject: [],
    emptyProject: false,
    is_archive: false,
  }),
  computed: {
    ...mapState({
      user: "user",
      isAuthResolved: "isAuthResolved",
      isPerksResolved: "isPerksResolved",
      isOneProjectResolved: "isOneProjectResolved",
    }),
  },
  watch: {
    "$store.state.perks": function () {
      this.perks = this.$store.state.perks;
      this.perks.length === 0
        ? (this.emptyProject = true)
        : (this.emptyProject = false);
    },
    "$store.state.oneProject": function () {
      this.oneProject = this.$store.state.oneProject;
    },
  },
  async mounted() {
    if (this.$route.query.archive === "true") this.is_archive = true;
    this.getAllPerks(this.$route.params.projectTitle);
    this.getOneProject(this.$route.params.projectTitle);
  },
  methods: {
    ...mapActions(["getAllPerks"]),
    ...mapActions(["getOneProject"]),
  },
};
</script>

<style>
.to-back-text {
  margin-left: 14px !important;
  margin-top: 5px !important;
}
</style>
