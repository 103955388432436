<template>
  <v-row justify="center">
    <v-col cols="auto" style="padding-top: 29px">
      <Images class="" name="frame.svg" />
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="auto">
      <Heading type="h4 grey2">Hey {{ user.displayName }}!</Heading>
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="auto" style="text-align: center; padding-top: 0">
      <Texting type="p2 grey2">{{ text }}</Texting>
    </v-col>
    <div style="width: 100%"></div>
  </v-row>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Heading from "./Heading";
import { mapState } from "vuex";
export default {
  components: {
    Images,
    Texting,
    Heading,
  },
  props: {
    text: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
};
</script>
